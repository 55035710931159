<template>
  <default-field
    v-model="value"
    v-bind="labelProps"
    @reset="resetField"
    @listen="handleListen"
  >
    <editor-quill  v-bind="fieldAttrs" v-model="value" @upload-open="handleUploadOpen" ref="quill"></editor-quill>
    <dialog-attachment ref="attachment"  v-bind="uProps" @select="handleSelectImages"></dialog-attachment>
  </default-field>
</template>

<script>
  import {computed, ref, useAttrs, onMounted} from "vue";
  import {baseProps} from '../Composition/FormField';
  import DialogAttachment from "../../Attachment/dialog-attachment"
  export default {
    name: 'QuillField',
    props: {
      ...baseProps,
      action: {
        type: String,
        default: '/admin/attachment/upload'
      },
      /**
       * 上传类型 image|images|file
       */
      type: {
        type: String,
        default: 'image'
      },
      uploadComponent: {
        type: String,
        default: 'image-upload'
      },
      moduleName:{
        type: String,
        default: 'admin',
      },
      uploadProps:{
        type:Object,
        default:() => {}
      },
      options:{
        type:Object,
        default:() => {}
      }
    },
    components:{
      DialogAttachment
    },
    setup(props) {

      const attrs = useAttrs();
      const value = ref('');
      /**
       * label
       */
      const labelProps = computed(() => {
        return {
          ...props,
          ...attrs
        };
      });

      const uProps = computed(() => {
        let attrs = {
          ...props.uploadProps,
          moduleName:props.moduleName
        };
        return attrs
      })


      /** 接管验证 start ******/
      const validate = () => {
        return false;
      };

      /** 处理表单重置 **/
      const resetField = (data) => {

        if (data) {
          value.value = data;
          return;
        }
        value.value = initialValue();
      };

      /**
       * 初始化值
       */
      const initialValue = () => {
        return !(props.default === undefined || props.default === null)
          ? props.default
          : '';
      };

      const isDisabled = ref(false)
      const handleListen = (event) => {
        if (event && event.action) {
          switch (event.action) {
            case 'disabled':
              isDisabled.value = event.params;
              break;
          }
        }
      }

      const fieldAttrs = computed(() => {
        return {
          ...attrs,
          disabled: isDisabled.value,
          options:{
            ...props.options,
          },
          ...props.uploadProps,
        };
      });

      onMounted(() => {
        isDisabled.value = props.disabled
        value.value = initialValue();
      })


      const attachment = ref(null)
      const quill = ref(null)

      const handleSelectImages = function(images){
        let url = []
        images.forEach((img) => {
          url.push({
            type:'image',
            url:img.image
          })
        })
        quill.value.setUploadFiles(url)
      }

      const handleUploadOpen = function(){
        attachment.value.openDialog()
      }

      return {
        resetField,
        validate,
        handleUploadOpen,
        handleSelectImages,
        handleListen,
        fieldAttrs,
        uProps,
        attachment,
        quill,
        labelProps,
        attrs,
        value
      }
    },
  }
</script>
<style scoped>

</style>
